<template>
	<div id="my_task">
		<el-row>
			<el-col :span="3">
				<el-select v-model="tableInfo.conf.product_id" clearable filterable placeholder="全部产品"
					@change="searchTaskList(1)">
					<el-option v-for="item in $task_.state.task_manage.product_list" :label="item.title"
						:value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="3">
				<el-select v-model="tableInfo.conf.type" clearable filterable placeholder="全部类型"
					@change="searchTaskList(1)">
					<el-option v-for="item in $task_.state.task_manage.task_type" :label="item.title" :value="item.id">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="3">
				<el-select v-model="tableInfo.conf.status" clearable filterable placeholder="全部状态"
					@change="searchTaskList(1)">
					<el-option v-for="item in tableInfo.conf.status_ids" :label="item.title" :value="item.value">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="3">
				<el-select v-model="tableInfo.conf.end_status" clearable v-if="tableInfo.conf.status == 2" filterable
					placeholder="到期时间" @change="searchTaskList(1)">
					<el-option v-for="item in tableInfo.conf.ends" :label="item.title" :value="item.value">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="1" :offset="6">
				<el-input placeholder="ID" clearable v-model="tableInfo.conf.task_id" @keyup.enter="searchTaskList(2)"
					@clear="searchTaskList(2)">
				</el-input>
			</el-col>
			<el-col :span="5">
				<el-input placeholder="任务名称、创建人姓名或英文简称" clearable v-model="tableInfo.conf.keywords"
					@keyup.enter="searchTaskList(2)" @clear="searchTaskList(2)">
					<template #append>
						<i class="iconfont icon-edit" @click="searchTaskList(2)">&#xe6ab;</i>
					</template>
				</el-input>
			</el-col>
		</el-row>
		<div class="table">
			<el-table
			      :data="tableInfo.list"
			      style="width: 100%; margin-bottom: 20px"
			      row-key="task_id"
			      border
			      default-expand-all
				  @sort-change="tableSort"
			    >
			      <el-table-column :label="v.label" :width="v.width" :prop="v.prop" :sortable="v.sortable" :fixed="v.fixed"
			      	v-for="(v,i) in tableInfo.columns" :key="i" >
			      	<template #default="scope">
			      		<!-- 访问链接 -->
			      		<div v-if="v.type === 'link'">
			      			<el-link type="primary" target="_blank" :href="scope['row'][v.prop]" title="点击访问链接"
			      				v-if="scope['row'][v.prop] != null">{{scope['row'][v.prop]}}</el-link>
			      			<el-tooltip class="item" effect="dark" content="尚未设置,无法访问" placement="top" v-else>
			      				<span class="error-text">暂无信息</span>
			      			</el-tooltip>
			      		</div>
			      		<!-- 访问链接 -->
			      		<!-- checkbox开启/关闭 -->
			      		<div v-else-if="v.type === 'checkbox'">
			      			<el-switch :value="String(scope['row'][v.prop])" active-value="1" inactive-value="0"
			      				@change="setCheckbox(scope,v.prop,v.action)" :disabled="v.disabled"
			      				v-if="scope['row'][v.prop] != null">
			      			</el-switch>
			      			<el-tooltip class="item" effect="dark" content="无法设置,请联系开发人员" placement="top" v-else>
			      				<el-switch :value="false" disabled></el-switch>
			      			</el-tooltip>
			      		</div>
			      		<!-- checkbox开启/关闭 -->
			      		<!-- 按钮操作 -->
			      		<div v-else-if="v.type === 'button'">
			      			<el-button :type="bv.type" :size="bv.size ? bv.size : 'medium'" :round="bv.round"
			      				:class="bv.class" :disabled="bv.disabled" @click="clickBtn(scope,bv.action)"
			      				v-for="(bv,bi) in v.btns" :key="bi">{{bv.name}}</el-button>
			      		</div>
			      		<!-- 按钮操作 -->
			      		<!-- 图标操作 -->
			      		<div v-else-if="v.type === 'button_icon'">
			      			<i class="iconfont icon-edit" :title="iv.name" @click="clickBtnIcon(scope,iv.action)"
			      				v-for="(iv,ii) in v.btns_icon" :key="ii" v-html="iv.icon"></i>
			      		</div>
			      		<!-- 图标操作 -->
			      		<!-- 普通文本编辑 -->
			      		<div v-else-if="v.type === 'edit'">
			      			<span class="set-text" v-if="scope['row'][v.prop] != null">
			      				<span v-if="v.disabled === true">{{scope['row'][v.prop]}}</span>
			      				<span @click="setText(scope,v.prop,v.label,v.type,v.action)"
			      					v-else>{{scope['row'][v.prop]}}</span>
			      			</span>
			      			<el-tooltip class="item" effect="dark" content="无法设置,请联系开发人员" placement="top" v-else>
			      				<span class="error-text">暂无信息</span>
			      			</el-tooltip>
			      		</div>
			      		<!-- 普通文本编辑 -->
			      		<!-- 点击文本显示 -->
			      		<div v-else-if="v.type === 'click'">
			      			<span v-if="scope['row'][v.prop] != null">
			      				<span v-if="v.isSumVerify === true && parseInt(scope['row'][v.prop]) <= 0">
			      					{{scope['row'][v.prop]}}
			      				</span>
			      				<span class="table-click-text" :title="scope['row'][v.prop]" :style="{'color':v.color}"
			      					@click="clickText(scope['row'],v.action)" v-else>{{scope['row'][v.prop]}}</span>
			      			</span>
			      			<el-tooltip class="item" effect="dark" content="未设置,请联系开发人员" placement="top" v-else>
			      				<span class="error-text">暂无信息</span>
			      			</el-tooltip>
			      		</div>
			      		<!-- 点击文本显示 -->
			      		<!-- 普通文本显示 -->
			      		<div v-else>
			      			<span
			      				:class="[v.class ? v.class_prefix + ' ' + v.class_prefix+'_' + scope['row'][v.class_prop] : '']"
			      				v-if="scope['row'][v.prop] != null" :style="{'color':scope['row'][v.color]}">
			      				{{scope['row'][v.prop]}}
			      			</span>
			      			<el-tooltip class="item" effect="dark" content="未设置,请联系开发人员" placement="top" v-else>
			      				<span class="error-text">暂无信息</span>
			      			</el-tooltip>
			      		</div>
			      		<!-- 普通文本显示 -->
			      	</template>
			      </el-table-column>
			    </el-table>
				<el-pagination class="pagination" background :current-page="tableInfo.conf.curPage" :page-size="tableInfo.conf.pageLimit"
					:page-sizes="tableInfo.conf.pageLimits ? tableInfo.conf.pageLimits : pageConf.pageLimits"
					:layout="tableInfo.conf.layout ? tableInfo.conf.layout: pageConf.layout" :total="tableInfo.conf.dataTotal"
					:disabled="tableInfo.conf.pageStatus" :hide-on-single-page="tableInfo.conf.sigleHide ? tableInfo.conf.sigleHide : false"
					@size-change="tablePageSize" @current-change="tableChosePage">
				</el-pagination>
			<!-- <CusTable ref="cusTable" title="任务管理" :datas="tableInfo.list" :cusConf="tableInfo.conf"
				:cusColums="tableInfo.columns" @chosePage="tableChosePage" @setPageSize="tablePageSize"
				@clickText="openShowPage" @showTaskProgress="showTaskProgress" @tableSort="tableSort">
			</CusTable> -->
		</div>
		<div class="dialog-page">
			<ShowTask ref="showRef" @load_table='loadTaskList'></ShowTask>
			<ShowProcess ref="processRef" @load_table='loadTaskList' @load_table_detail="openShowPage"></ShowProcess>
		</div>
	</div>
</template>

<script type="text/javascript">
	import tableColumns from '@/assets/json/task/table/my-task-cloumns.json'
	import CusTable from '@/components/common/CusTable.vue'
	import ShowTask from '@/components/task/TaskToDetail.vue'
	import ShowProcess from '@/components/task/TaskToProcess.vue'
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive,
		watch
	} from 'vue'
	export default defineComponent({
		name: 'my_task',
		components: {
			CusTable,
			ShowTask,
			ShowProcess
		},
		setup(props, context) {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 获取任务类型
			const getTaskType = () => {
				// 获取任务类型
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.task_type_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							ctx.$task_.commit('setTaskManageToTaskType', respon.Data)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 获取产品信息
			const getProduct = () => {
				// 获取产品信息
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.product_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							ctx.$task_.commit('setTaskManageToProductList', respon.Data)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}

			// 列表信息
			const tableInfo = reactive({
				list: [],
				columns: ctx.$pubFun_.verify_tableColumns(tableColumns.list, 'TaskManage'),
				conf: {
					loadStatus: false,
					curPage: 1,
					pageLimit: 15,
					dataTotal: 0,
					sigleHide: true,
					task_id: ctx.$route.query.task_id ? ctx.$route.query.task_id : '',
					keywords: '',
					product_id: '',
					sort_field: '',
					sort_order: '',
					type: '',
					status: 2,
					emptyText: '',
					end_status: 1,
					ends: [{
						title: '当天到期',
						value: 1
					}],
					status_ids: [{
						title: "进行中",
						value: 2
					}, {
						title: "已完成",
						value: 3
					}, {
						title: "已发布",
						value: 5
					}, {
						title: "已撤销",
						value: 4
					}]
				}
			})

			// 顶部搜索栏
			const searchTaskList = (type) => {
				if (type === 1) {
					//select筛选，清空任务ID、关键字查询
					tableInfo.conf.task_id = ''
					tableInfo.conf.keywords = ''
				} else if (type === 2) {
					//关键字查询，清空select筛选
					tableInfo.conf.product_id = ''
					tableInfo.conf.type = ''
					tableInfo.conf.status = ''
				}
				if (tableInfo.conf.status != 2) {
					tableInfo.conf.end_status = ''
				}

				// 重置分页参数
				tableInfo.conf.curPage = 1

				// 执行搜索请求
				loadTaskList()
			}

			// 获取任务列表
			const loadTaskList = (isOpenTaskDetail = false) => {
				// 重置参数
				tableInfo.list = []
				tableInfo.conf.loadStatus = true

				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.MyTask.list.url, {
						page: tableInfo.conf.curPage,
						limit: tableInfo.conf.pageLimit,
						task_id: tableInfo.conf.task_id,
						product_id: tableInfo.conf.product_id,
						type: tableInfo.conf.type,
						status: tableInfo.conf.status,
						keywords: tableInfo.conf.keywords,
						field: tableInfo.conf.sort_field,
						sort: tableInfo.conf.sort_order,
						near_expire: tableInfo.conf.end_status
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 循环存放任务数据
							tableInfo.list = respon.Data.datas
							tableInfo.list.forEach(res=>{
								res.children = res.taskList.map(resp =>{
									return {
										task_id:resp.ytl_num,
										title:resp.ytl_title,
										task_type_name:res.task_type_name,
										task_status_name:resp.ytl_status_name,
										team_name:resp.team.ytt_title,
										complete_status:resp.complete_status,
										complete_status_color:resp.complete_status_color,
										task_progress:resp.task_progress,
										task_days:resp.task_days,
										uname:resp.creater.u_name,
										urgency_number:res.urgency_number,
										online_time:resp.online_time,
										type:'child'
									}
								})
							})
							tableInfo.conf.dataTotal = respon.Data.Count
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = '没有更多的数据了~'

							// 判断是否存在自动打开详情页
							if (isOpenTaskDetail === true && tableInfo.conf.task_id) {
								openShowPage(tableInfo.conf.task_id)
							}
						} else {
							// 关闭加载状态
							tableInfo.conf.loadStatus = false
							tableInfo.conf.emptyText = respon.Message
						}
					}).catch(error => {
						tableInfo.conf.loadStatus = false
						tableInfo.conf.emptyText = "服务器连接失败，请稍后重试"
					})
			}

			// 监听table排序变化
			const tableSort = (column) => {
				// 重置分页页码为1
				tableInfo.conf.curPage = 1

				// 存放排序字段和方式
				tableInfo.conf.sort_field = column.prop
				tableInfo.conf.sort_order = column.order === 'descending' ? 'desc' : (column.order === 'ascending' ? 'asc' : column.order )

				// 重新加载table数据
				loadTaskList()
			}

			// 设置table当前页码变化
			const tableChosePage = (curPage) => {
				// 存放当前页面
				tableInfo.conf.curPage = curPage

				// 重新加载table数据
				loadTaskList()
			}

			// 设置table每页页码数
			const tablePageSize = (limit, page) => {
				// 存放页码数
				tableInfo.conf.pageLimit = limit

				// 判断查询当前页  *  条数 > 总条数不进行查询
				if (page === 1 || limit * page <= tableInfo.conf.dataTotal) {
					// 重新加载table数据
					loadTaskList()
				}
			}

			// 任务详情挂载
			const showRef = ref(null)

			// 打开任务详情界面
			const openShowPage = (task_id) => {
				
				showRef.value.openPage(task_id)
			}

			// 任务进度挂载
			const processRef = ref(null)

			// 打开任务进度界面
			const showTaskProgress = (task_id) => {
				processRef.value.openPage(task_id)
			}

			/*初次自动加载*/
			getTaskType()
			getProduct()

			loadTaskList(true)
			/*初次自动加载*/

			// 判断存在参数信息，有则清除掉
			if (JSON.stringify(ctx.$route.query) != '{}') {
				window.location.hash = ctx.$route.path
			}
			const pageConf = ref({
				pageLimits: [15, 50, 200, 500, 2000],
				layout: "prev, pager, next, total, jumper, sizes"
			})
			
			const clickText = (row, action) => {
				if(row.type == "child"){
					return
				}else{
					if(action == 'clickText'){
						openShowPage(row.task_id)
					}else if(action == 'showTaskProgress'){
						showTaskProgress(row.task_id)
					}
				}
			}
			
			const clickBtn = (list, action) => {
				/* 按钮点击事件 */
			
				context.emit(action, list.$index)
			}
			
			const clickBtnIcon = (list, action) => {
				/* 图标点击事件 */
				context.emit(action, list.$index)
			}
			
			
			return {
				clickText,
				clickBtn,
				clickBtnIcon,
				pageConf,
				tableInfo,
				loadTaskList,
				tableSort,
				tableChosePage,
				tablePageSize,
				searchTaskList,
				showRef,
				openShowPage,
				processRef,
				showTaskProgress
			}
		}
	})
</script>
<style lang="less" scoped>
	:deep .el-table--border .el-table__cell:first-child .cell{
		display: flex;
	}
</style>
